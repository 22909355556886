.container-home {
    padding: 50px 12px;
    text-align: start;
    background-color: #282E34;
}


.home-item {
    height: 300px;
    margin-top: 24px;
    display: 'flex';
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    cursor: pointer;


}

.check {

    & :hover {

        transform-style: preserve-3d;
        transform-origin:top left;
        transform:translateY(-10px) rotateY(0);
        transition:0.7s;
    
      }
}

.home-item img {
    max-width: 100%;
    max-height: 100%;
    background-position: 'center';
    background-size: 'cover';
    width: 100%;
    /* height: auto; */
    object-fit: cover;

}

.item-description {
    position: absolute;
    width: 100%;
    height: 40%;
    background-color: rgba(0, 0, 0, .73);
    bottom: 0;
    left: 0;

    /* opacity: 0.2; */

}

.home-item-title {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 6px 12px;

}

.home-item-description {
    padding: 0px 12px;


}

.text-title {
    font-size: 12px;
    color: #fff;
}

.text-desciption {
    font-size: 10px;
    color: #fff;
    height: 50px;
    overflow: hidden;
}

