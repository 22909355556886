.nav {
    width: 100%;
    background-color: #fff;
    /* padding: 20px 20px; */
    position: relative;

}

.nav-box {
    position: absolute;
    right: 0;
    width: 100%;
    justify-content: flex-end;
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    bottom: -45px;
}

.nav-item {
    width: 50%;
    background-color: #ccc;
    position: relative;

}

.triangle {
    /* width: 0;
    height: 0; */
    border-left: 35px solid transparent;
    border-right: 35px solid transparent;
    border-bottom: 35px solid #ccc;
    position: absolute;
    left: -35px;
}

.nav-item-icon {
    height: 50px;
    display: flex;
    justify-content: space-around;
}

.item-menu-top {
    /* background-color: aqua; */
    align-items: center;
    line-height: 1px;
}


.text-menu {
    font-style: italic;
    font-size: 14px;
}

.text-menu:hover {
    /* color: red; */
    cursor: pointer;
    font-weight: 900;
    animation: moveAndResize 0.5s forwards;
}

.text-menu-active {
    font-weight: 900;
    animation: moveAndResize 0.5s forwards;
    font-style: italic;
    font-size: 14px;
    cursor: pointer;
}

/* .customMenuMobie .ant-menu-item-selected {
    background-color: #e8e6e6 !important;
    color: #000 !important;

}

.ant-menu-item-group-list .ant-menu-item-selected {
    background-color: #e8e6e6 !important;
    color: #000 !important;
}

.ant-menu-submenu-selected {
    background-color: #e8e6e6 !important;
    color: #000 !important;
}

.ant-menu-dark .ant-menu-submenu-selected >.ant-menu-submenu-title{

    color: #000 !important;
}

.ant-menu-item-group {
    background-color: #fff !important;
}
.ant-menu-title-content {
    color: #000 !important;
}

.customMenuMobie span {
    color: #000 !important;

}
.ant-menu-item-group-title {
    display: none;
} */

@keyframes moveAndResize {
    100% {
        transform: scale(1.25);
    }

    /* 100% {
        transform: translateY(-20px) scale(1);
    } */
}

.item-menu-bottom {
    background-color: #000;
    position: relative;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5px;
    /* clip-path: polygon(0 25%, 25% 0, 100% 0, 100% 100%, 75% 100%, 0 75%); */


}

@media (min-width:0px) and (max-width:695px) {
    .nav-box {
        display: none;
    }

    .nav-box-mobie {
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        left: 0;
        background-color: #000;
    }
}

@media (min-width:696px) and (max-width:1023px) {
    .nav-box-mobie {
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        left: 0;
        background-color: #000;
    }

    .nav-box {
        display: none;
    }
}

@media (min-width:1024px) {
    .nav-box-mobie {
        display: none;
        /* max-width: 100%; */
    }
}