.about-home {
    color: #777;
    background-color: white;
    text-align: center;
    padding: 50px 80px;
    text-align: justify;

}

.about-home h3 {
    display: flex;
    justify-content: center;
    letter-spacing: 5px;
    text-transform: uppercase;
    font: 20px "Lato", sans-serif;
    color: #111;
}


@media (min-width:0px) and (max-width:695px) {


    .about-home {
        padding: 50px 30px;
        
    
    }


}

@media (min-width:696px) and (max-width:1023px) {}

@media (min-width:1024px) {}