

.container-profile-player {
    border: 1px solid #e6e1e1;
}

.item-profile-player-left {
    background-color: rgb(234, 216, 183);
    height: 100%;
    padding: 0 12px;
    display: flex;
    flex-direction: column;
    gap: 10px;

}

.img-profile {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.img-profile img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
}

.contact-profile {}

.icon-contact-profile {
    /* border: 1px solid #000; */
    /* height: 30px;
    width: 30px; */
    /* border-radius: 50%; */
    padding: 2px 2px;
    margin-right: 6px;
}


.item-profile-player-right {
    padding: 0 16px;
}

.target-player {

}

.team-profile {

}

.contact-profile p {    
    font-size: 12px;
}