.container-ranking {
    padding: 0px 0px;
}

.header-ranking {
    background-color: #ccc;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 140px;
    background-image: url(/public/banner-ranking.png);
    color: #fff;
}
.title {
    /* display: flex; */
    justify-content: space-between;
    padding: 0 25px;
}

.choose-age {
    display: flex;
    align-items: center;
    padding: 12px 12px;
    gap: 12px;
}
.title-ranking{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.btn-login {
    width: 100px;
    height:30px;
    background: #000;
    color: #000;
    border: none;
    border-radius: 5px;
    font-weight: 900;
    margin: 12px 0;
}

.btn-login:hover{
    background: #000;
    color: #fff;
}

.custom-modal .ant-modal-content {
    padding: 0;
  }

  .ant-modal-content button {
    /* display: none; */
  }



  @media (min-width:0px) and (max-width:695px) {
    .choose-age {
        overflow: scroll;
        width: 80%;
        scrollbar-width: none;
    }
  }

  @media (min-width:696px) and (max-width:1023px) {}

  @media (min-width:1024px) {
      .container {
        /* margin: 0 50px; */
      }
  }


