.container-profile-team {
    /* background-color: #282e34; */


}

.info-team {
    padding: 24px 50px;
    /* background-color: #282e34; */
    text-align: start;
    background-image: url("../../../public/banner-ranking.png");
    font-style: italic;

}


.logo-profile-team {
    height: 150px;
    margin-top: 12px;
}

.logo-profile-team img {
    width: 100%;
    height: 100%;
}

.information-profile-team {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;

}

.text-profile-team {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    background: linear-gradient(45deg, #fff, #ff9900, #33cc33, #3399ff); /* Gradient màu */
    -webkit-background-clip: text; /* Cắt nền cho văn bản trên Chrome/Safari */
    -webkit-text-fill-color: transparent; /* Làm cho văn bản trong suốt để thấy gradient */
    background-clip: text; /* Cắt nền cho văn bản trên Firefox */
}