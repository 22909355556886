.cms-search-player {
    padding: 12px 12px;
    text-align: start;
}

.cms-search-player-border {
    padding: 6px 12px;
    display: flex;
    justify-content: space-between;
    width: 30%;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .1), 0 8px 16px rgba(0, 0, 0, .1);
    box-sizing: border-box;
    padding: 12px 12px;
    z-index: 1;
    background-color: #fff;
    align-items: center;

}

.cms-search-player-border button{
    border: none;
    background-color: black;
    color: #fff;
    font-weight: 600;
    padding: 4px 6px;
    border-radius: 5px;

}
.cms-search-player-input {
    border: none;
    outline: none;
    width: 70%;
    height: 100%;
}