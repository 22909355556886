.container-activity {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.activity {
    width: 250px;
    height: 70px;
    perspective: 1000px;
    cursor: pointer;

}

.activity-circle { 
  position: absolute;
  top: 53%; /* Đưa phần tử đến giữa theo chiều dọc */
  left: 50%; /* Đưa phần tử đến giữa theo chiều ngang */
  transform: translate(-50%, -50%);
}

.circle {
  position: relative;
}

.activity img {
    max-width: 100%;
    max-height: 100%;
}

.animation {
  animation: flipImage 1.2s infinite;
}

@keyframes flipImage {
    0% {
      transform: rotateY(0deg);
    }
    50% {
      transform: rotateX(90deg);
    }
    100% {
      transform: rotateY(180deg);
    }
    0% {
      transform: rotateY(0deg);
    }
  }