.container-login-form {
    display: flex;
    justify-content: space-around;
    max-width: 100%;
    height: 300px;
  background-color: #fff;

}

.title-login-form {
    width: 55%;
    background-color: #000;
    display: flex;
    flex-direction: column;
    padding: 0px 40px 30px 0;    
    clip-path: polygon(0 0, 100% 0, 75% 100%, 0 100%);


}


.group-login-form {
    width: 45%;
    padding: 75px 24px 0px 12px;
    background-color: #fff;


}

.container-login-form .title-login-form {
    display: flex;
    justify-content: center;
    align-items: center;
}

.group-login-form Form{
    animation: animate 1.5s ease-in-out 1 forwards;


}

.title-login-form .name-login-form {
    font-size: 12px;
    /* text-transform: uppercase; */
    font-weight: bold;
    opacity: 0;
    animation: animate 1.5s ease-in-out 1 forwards;
    color: #fff;
}

.title-login-form .img-login-form {

    width: 150px;
    height: 70px;
    border-radius: 12px;
    opacity: 0;
    animation: animate 1s ease-in-out 1 forwards;
    color: #fff;
}

.btn-login-form {
    width: 100%;
    text-align: end;
    
}

.btn-login-form button{
    border: none;
    background: #000;
    color: #fff;
    padding: 6px 24px;
    border-radius: 5px;
    font-weight: 800;
}

.btn-login-form button:hover {
    background-color: #ccc;
}

@keyframes animate {
    from {
        opacity: 0;
        transform: translate(0, 100px);
        filter: blur(33px);
    }

    to {
        opacity: 1;
        transform: translate(0);
        filter: blur(0);
    }
}