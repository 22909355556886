.container-create-player {
    width: 100%;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}


.form-create-player {
    display: flex;
    flex-direction: column;
    width: 20%;
    /* align-items: center; */
    gap: 10px;
}

.label-creater-player {
    padding: 0px 6px;
}

.input-create-player {
    border-radius: 7px;
    padding: 0px 12px;
    border: 1px solid #ccc;
    /* width: 100%; */
    outline: none;
    height: 40px;
}
.form-create-player-category {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 10px;

}

.btn-submit-creater-player {
    background-color: black;
    color: #fff;
    border: none;
    padding: 12px 12px;
    border-radius: 5px;
    font-weight: 700;
}