.header-home {
    /* height: 140px; */
    --tw-bg-opacity: 1;
    background-color: rgb(67 69 88 / var(--tw-bg-opacity));

}

.title {
    padding: 6px 12px;
    width: 80%;
}

.title p {
    font-size: 20px;
}

.top-player-slider {
    overflow: hidden;
    width: 100%;
    position: relative;
    display: flex;
    animation: animate 1s ease-in-out 1 forwards;



}


.top-player-list {
    align-items: center;
    color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 20px 24px;
    gap: 30px;

    transition: transform 0.3s ease;
}

.button-slide {
    border: none;
    font-weight: 900;
    cursor: pointer;
    width: 40px;
    background-color: transparent;
    color: #000;
}

.btn-group-header-home {
    position: absolute;
    width: 10%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 1;
    /* left: -20px; */
}

.top-player-list-item {
    height: 100px;
    background-color: #fff;
    width: 230px;
    border-radius: 10px;
    display: flex;
    gap: 10;
    justify-content: space-around;
    position: relative;
    flex: 0 0 auto;
    border-bottom: 2px solid rgb(29, 208, 29);
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 2.1), 0 8px 16px rgba(0, 0, 0, 1.4);
    /* margin-right: 10px; */

}

.flex-end-item {

    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}

.logoTeam {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: absolute;
    right: 14px;
    top: -20px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;


    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .1), 0 8px 16px rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
    z-index: 1;
}

.logoTeam img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    /* hoặc object-fit: contain; */
}

.number-ranking {
    color: #000;
    font-weight: 700;
    font-size: 60px;
}

.top-player-list-item:hover {
    background-color: #ccc;
}

.container-header-home img {
    height: 140px;
}

.line-header-home {
    width: 100%;
    height: 3px;
    background-color: #1dd01d;
}

@media (min-width:0px) and (max-width:575px) {
    .title p {
        font-size: 10px;
    }

    .container-header-home {
        margin-bottom: 5px;
    }

    .container-header-home img {
        height: 70px
    }

    .line-header-home {
        width: 100%;
        height: 3px;
        background-color: #1dd01d;
    }

}