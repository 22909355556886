.App {
  text-align: center;
  position: relative;
  font-family: "Montserrat", "Helvetica", "sans-serif" ; 


}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
