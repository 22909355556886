.background-contact {
    /* The image used */
    background-image: linear-gradient(rgba(0, 0, 0, 0.208), rgba(0, 0, 0, 0.244)), url("../../Image/logoteam.png");

    /* Set a specific height */
    height: 100%;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.img-contact {
    width: 100%;
    height: 100%;
}

.text-contact {
    width: 100%;
    height: 100%;
    letter-spacing: 2px;
    text-transform: uppercase;
    font: 12px "Lato", sans-serif;
    color: #111;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
}



/* test book  */



