.container-news {
    padding: 12px 12px;
    text-align: start;
    display: flex;
    align-items: center;
    justify-content: center;
}

.article-detail {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 70%;
}

.article-detail h2 {
    font-size: 2rem;
    margin-bottom: 10px;
}

.article-detail .metadata {
    color: #666;
    margin-bottom: 10px;
    font-size: 12px;
}

.article-detail img {
    width: 100%;
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.article-detail p {
    line-height: 1.8;
    color: #333;
}

.back-button {
    display: inline-block;
    margin-top: 20px;
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 4px;
}

.back-button:hover {
    background-color: #555;
}


@media (min-width:0px) and (max-width:695px) {
    .article-detail {
        width: auto;
    }
  
}

@media (min-width:696px) and (max-width:1023px) {
    
  
}

@media (min-width:1024px) {
  
}
