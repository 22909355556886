.mb-30 {
    margin-bottom: 30px;
}

.mt-24 {
    margin-top: 24px;
}

.mt-48 {
    margin-top: 48px;
}
.table-ranking {
    /* border: 1px solid #ccc; */
    width: 100%;
    border-radius: 5px;
    /* background-image: url('../../../public/banner-ranking.png'); */

}

.tab-ranking {
    display: flex;
    justify-content: space-between;
    padding: 0px 50px;
    background-color: #fff;
    align-items: center;
    border-bottom: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4), 0 8px 16px rgba(0, 0, 0, 0.4);
}

.item-ranking {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 50px;
    border-radius: 20px;
    /* background-color: #ccc; */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1);
    height: 140px;
    cursor: pointer;
    animation: animate 1s ease-in-out 1 forwards;

}


.rank {
    width: 5%;
    color: #000;
    font-weight: 900;

}


.name {
    display: flex;
    width: 30%;
    gap: 5px;
    align-items: center;
    height: 100%;

}

.name:hover {
    color: rgb(133, 30, 30);
    cursor: pointer;
}

.team {
    display: flex;
    width: 30%;
    gap: 20px;
    align-items: center;

}

.age {
    width: 5%;

}


.point {
    width: 20%;
    font-weight: 900;

}

.avatar-name {
    width: 124px;
    /* border: 1px solid #000; */
    border-radius: 50%;
    height: 90%;
    align-items: center;
    justify-content: center;
    display: flex;
}



.avatar-team {
    width: 80px;
    /* border: 1px solid #ccc; */
    height: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 5px;
}

.search {
    width: 10%;
}

.search-input {
    border-radius: 3px;
    border: none;
    outline: none;
    width: 100%;
    height: 100%;

}

.search-group {
    height: 25px;
    width: 100%;
    /* color: rgb(249, 106, 106); */
}

.btn-export {

    background-color: #000;
    border: 1px solid #000;
    color: #fff;
    font-weight: 800;
    margin: 12px 24px;
    padding: 12px 24px;
    border-radius: 5px;
    cursor: pointer;

}

.btn-export:hover {
    opacity: 0.5;
}

.top-ranking-icon {
    width: 40px;
    height: 40px;
}

.top-player-ranking-detail {
    /* width: 100%; */
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 50px;
    /* background-color: #000; */
    gap: 20px;
    padding: 30px 0;
    /* background-image: url('../../../public/banner-ranking.png'); */


}

.top-player-ranking-detail .box-top-player {
    height: 100%;
    width: 20%;
    background-color: #000; 
    border-radius: 0 50px 0 50px;
    overflow: hidden;
    position: relative;
    z-index: 2;
}


.animation1{
    animation: slide 0.75s linear forwards;
}
.animation2{
    animation: slide 1s linear forwards;
}

.animation3{
    animation: slide 0.5s linear forwards;
}

@keyframes slide {
    0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(0%);
      }
  }

.box-top-team {
    height: 50px;
    position: absolute;
    width: 100%;
    z-index: 1;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    color: #fff;
    font-weight: 700;
    border-radius:  0 0 0 50px;
}

.box-top-team a {
    font-size: 12px;
}
.box-top-ranking {
    position: absolute;
    z-index: 1;
    top: 0;
    text-align: start;
    padding: 12px 24px;
    font-weight: bold;
    font-size: 6rem;
    text-align: center;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(250, 250, 250, 0.3);
}


.bgrank1 {
    background: linear-gradient(45deg, #FFD700, #FFA500, #FF6347, #FF4500);
}

.bgrank2 {
    background: linear-gradient(45deg, #c0c0c0, #d3d3d3, #b0b0b0, #e5e5e5);

}

.bgrank3 {
    background: linear-gradient(45deg, #8B4513, #A0522D, #6F4F28, #4A3C2F);

}
.rank1 {
    background: linear-gradient(45deg, #FFD700, #FFA500, #FF6347, #FF4500);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.rank2 {
    background: linear-gradient(45deg, #c0c0c0, #d3d3d3, #b0b0b0, #e5e5e5);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.rank3 {
    background: linear-gradient(45deg, #8B4513, #A0522D, #6F4F28, #4A3C2F);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.box-top-point {
    position: absolute;
    /* z-index: 1; */
    /* text-align: center; */
    font-weight: 900;
    color: rgb(22, 163, 74);
    text-shadow: 2px 2px 4px rgba(250, 250, 250, 0.3);
    width: 70px;
    height: 50px;
    background-color: #000;
    top: 0px;
    right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius:  0 50px 0 40px;
}
.box-top-point a {
    font-size: 16px;    

}




@media (min-width:0px) and (max-width:695px) {
    .age {
        display: none;

    }

    .exam {
        display: none;
    }

    .avatar-name {
        /* display: none; */
        width: 50px;
        height: 40%;

    }

    .team {
        width: 20%;
        font-size: 10px;
        text-align: start;


    }

    .avatar-team {
   display: none;
    }


    .rank {
        font-size: 10px;

    }

    .change {
        font-size: 10px;


    }

    .name {
        font-size: 10px;
        text-align: start;

    }

    .point {
        font-size: 10px;

    }

    .search {
        font-size: 10px;
    }

    .btn-login {
        display: none;
    }

    .top-ranking-icon {
        width: 25px;
        height: 25px;
    }

    .item-ranking {
        margin: 16px 0px;
        padding: 0 12px;
        height: 100px;
    }

    .tab-ranking {
        padding: 0 12px;
    }
.top-player-ranking-detail .box-top-player {
    width: 33%;
    border-top-right-radius: 40px;
    border-bottom-left-radius: 40px;
}

.top-player-ranking-detail {
    margin: 16px 12px;
    height: 250px;
}

.box-top-team img {
    width: 30px !important;
    height: 30px !important;
    border-radius: 50%;
    margin-left: 12px;
    margin-top: 3px;
}

.box-top-team a {
    font-size: 8px;
    margin-top: 10px;

}

.box-top-team  {
   align-items: normal;
    height: 40px;
}

.box-top-ranking  {
    padding: 12px 12px;
    font-size: 3rem;
}
.box-top-point  {
    width: 60px;
    height: 40px;
}

.box-top-point  a{
    font-size: 12px;
}
}

@media (min-width:696px) and (max-width:1023px) {
    .avatar-name {
        width: 80px;
        height: 50%;

    }

    .name {
        font-size: 14px;
        width: 30%;
    }

    .name  p {
        font-size: 14px;
        width: 60%;
    }

    .avatar-team {
        /* display: none; */
        width: 60px;
        height: 30px;
    }

    .team {
        width: 30%;
        font-size: 10px;

    }

    .team {
        width: 30%;
        font-size: 10px;

    }
.box-top-team a {
    font-size: 10px;
}

.box-top-team img {
    width: 30px !important;
    height: 30px !important;
    border-radius: 50%;
    margin-left: 16px;
}
.box-top-point  {
    width: 60px;
    height: 40px;
}

.box-top-point  a{
    font-size: 12px;
}
}

@media (min-width:1024px) {

    .name {
        gap: 20px;

    }
.top-player-ranking-detail .box-top-player {
    width: 15%;
}
}


@keyframes animate {
    from {
        opacity: 0;
        transform: translate(0, 100px);
        filter: blur(33px);
    }

    to {
        opacity: 1;
        transform: translate(0);
        filter: blur(0);
    }
}