.home-container {

    /* The image used */
    background-image: linear-gradient(rgba(0, 0, 0, 0.208), rgba(0, 0, 0, 0.244)), url("../../Image/logoteam.png");

    /* Set a specific height */
    height: 100%;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}